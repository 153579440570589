import axios from 'axios'
import { Notification } from 'element-ui'
import qs from 'qs'

// 可以跨域请求
axios.defaults.withCredentials = true
// 验证可用域名
axios.defaults.baseURL = ''
// axios.defaults.headers.post['Content-Type'] =
//   'application/x-www-form-urlencoded'
axios.defaults.validateStatus = (status: number): boolean => {
  return status < 600
}
interface Config {
  isLoading: boolean
  isMessage: boolean
  isJson?: boolean
}
interface CustomConfig {
  isLoading?: boolean
  isMessage?: boolean
  isJson?: boolean
}
interface FinalMyData {
  data: any
  config: Config
}
function handleResponse(response: any, config: Config) {
  return new Promise((resolve, reject) => {
    if (response.status === 200) {
      const errCode: number | undefined = response.data.errNo
      if (errCode) {
        if (config.isMessage) {
          if (errCode === 5000 || errCode === 4000) {
            // @ts-expect-error I see
            Notification.closeAll()
            Notification({
              title: '',
              message:
                errCode === 4000
                  ? '参数错误'
                  : '接口请求失败' + response.data.errMsg,
              position: 'top-right',
              type: 'warning'
            })
          } else {
            // @ts-expect-error I see
            Notification.closeAll()
            Notification({
              title: '',
              message:
                response.data.errMsg ||
                response.data.errStr ||
                response.data.errInfo ||
                response.data.errstr ||
                '出问题了~请检查响应信息',
              position: 'top-right',
              type: 'warning'
            })
          }
        }
        if (errCode === 1005 || errCode === 4100 || errCode === 3) {
          const redirectUrl = response.data.data.loginUrl
          // 登录失效，跳转登陆平台
          setTimeout(() => {
            window.location.href = redirectUrl
          }, 1000)
        }
        if (errCode == 2) {
          resolve(response.data)
        }
        reject(response.data)
      }
      resolve(response.data)
    } else {
      // @ts-expect-error I see
      Notification.closeAll()
      Notification({
        title: '',
        message: `服务故障，请重试（状态码：${response.status}）`,
        position: 'top-right',
        type: 'error'
      })
      reject(response.data)
    }
  })
}
function _get(
  path: string,
  myData: FinalMyData,
  loadingInstance: any
): Promise<any> {
  return axios
    .get(path, {
      params: myData.data
    })
    .then((response: any): Promise<any> => {
      if (loadingInstance) {
        loadingInstance.close()
      }
      return handleResponse(response, myData.config)
    })
}
function _post(
  path: string,
  myData: FinalMyData,
  loadingInstance: any
): Promise<any> {
  const params = myData.config.isJson ? myData.data : qs.stringify(myData.data)
  return axios.post(path, params).then((response: any): Promise<any> => {
    if (loadingInstance) {
      loadingInstance.close()
    }
    return handleResponse(response, myData.config)
  })
}
function _put(myData: FinalMyData, loadingInstance: any): Promise<any> {
  return axios.put(myData.data).then((response: any): Promise<any> => {
    if (loadingInstance) {
      loadingInstance.close()
    }
    return handleResponse(response, myData.config)
  })
}
function _file(
  path: string,
  myData: FinalMyData,
  loadingInstance: any
): Promise<any> {
  return axios
    .post(path, myData.data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
    .then((response: any): Promise<any> => {
      if (loadingInstance) {
        loadingInstance.close()
      }
      return handleResponse(response, myData.config)
    })
}

// 查找请求类型及地址
const factory = (obj: {
  [key: string]: any
}): { [key: string]: (...argv: object[]) => Promise<any> } => {
  const output: { [key: string]: any } = {}
  Object.keys(obj).forEach((methodKey) => {
    Object.keys(obj[methodKey]).forEach((apiKey) => {
      // 本地联调时,直接在访问地址中加上对应docker环境的ZYBUUAP值,有效期一天.失效后去
      const param = process.env.NODE_ENV === 'development' ? `` : ''
      const api: string = obj[methodKey][apiKey] + param
      output[apiKey] = (
        myData: any = {},
        myConfig: CustomConfig = {}
      ): Promise<any> => {
        const defaultConfig: Config = {
          isLoading: true,
          isMessage: true,
          isJson: false
        }
        const finalConfig: Config = Object.assign({}, defaultConfig, myConfig)
        const finalMyData: FinalMyData = {
          data: myData,
          config: finalConfig
        }
        let loading: any = null
        if (finalMyData.config.isLoading) {
          loading = null
        }
        if (finalMyData.config.isJson) {
          axios.defaults.headers['Content-Type'] = 'application/json'
        } else {
          axios.defaults.headers['Content-Type'] =
            'application/x-www-form-urlencoded'
        }
        switch (methodKey) {
          case 'get':
            return _get(api, finalMyData, loading)
          case 'post':
            return _post(api, finalMyData, loading)
          case 'put':
            return _put(finalMyData, loading)
          case 'file':
            return _file(api, finalMyData, loading)
          default:
            return Promise.resolve(null)
        }
      }
    })
  })
  return output
}
export default factory
