/*
 * @Author: sunchunyu02@zuoyebang.com
 * @Date: 2020-04-15 15:44:59
 * @LastEditors: Please set LastEditors
 * @Description: 异步请求API集合
 */
// const host = 'http://yapi.zuoyebang.cc/mock/3462';
const api = {
  // GET 请求
  get: {
    getHomeNews: '/official/show/webnews', // 首页展示
    getNewsList: '/official/news/list', // 资讯列表
    getNewsDetail: '/official/news/detail', // 获取资讯详情
    getQinhaiHomeList: '/official/jobdesign/excellentwork', // 青海官网首页
    getDivisionsubject: '/official/jobdesign/divisionsubject', // 学段学科
    getWorklist: '/official/jobdesign/worklist', // 作品列表
    getSubjectStatics: '/official/jobdesign/divisionsubjectstatistics', //学段学科作品数量统计
    getDownloadUrl: 'https://zypt.yunsizhixue.com/smart-api/version/newest' //获取pc端和大屏端的应用下载链接
  },

  // POST 请求
  post: {
    demo: '/demo/index',
    postWatch: '/official/jobdesign/watch', // 观看作品
    postThumb: '/official/jobdesign/addStar', // 点赞
    visitPage: '/official/jobdesign/visitincrease', // 页面访问记录
    postRankDivision: '/official/jobdesign/rankdivision' // 获取学端学科
  },

  // File 请求
  file: {},

  //put
  put: {
    upload:
      'https://zyb-zhike-1253445850.cos.ap-beijing.myqcloud.com/test.jpg?q-sign-algorithm=sha1&q-ak=AKIDTklUw83h4AzC9pFOJyNVNyJNAgvud5F9&q-sign-time=1631776191%3B1631776191&q-key-time=1631776191%3B1631776191&q-header-list=host&q-url-param-list=&q-signature=f4e57f9f8c74bb8e055be5eb202821bbed624d1c'
  },

  // Websocket 请求
  ws: {
    lonConUri: '/im/basic/longconnsign' // 长链接请求
  }
}

// 使用 yapi或线上接口
export default api
